<template>
        <v-row class="fill-height" align-content="center" justify="center">
            <v-col xs="12" md="6" class="text-center" style="border-right:1px solid #e0e0e0">
                <v-img :src="require('@/assets/images/lfa-logo.png')" aspect-ratio="2"></v-img>
            </v-col>
            <v-col xs="12" md="6" align-self="center">
                <div style="padding:0 35% 0 20px;">
                    <h1 class="font-weight-light grey--text text--darken-3">Admin panel login</h1>
                    <p class="caption grey--text text--darken-2">Enter your credentials to access the admin panel.</p>

                    <v-alert :value="alertVisible" type="warning">{{ errorMessage }}</v-alert>

                    <div v-show="view == 'login'">
                        <v-text-field v-model="username" label="Email" type="text" :error-messages="usernameError"
                            outline single-line @keydown.enter="login" ref="username" prepend-icon="mdi-account-outline"></v-text-field>
                        <v-text-field v-model="password" label="Password" type="password" :error-messages="passwordError" 
                            outline single-line @keydown.enter="login" ref="password" prepend-icon="mdi-lock"></v-text-field>

                        <v-btn block depressed color="grey darken-4" class="white--text" :loading="loading" @click="login">Sign In</v-btn>
                    </div>

                    <div v-show="view == 'change'">
                        <v-text-field v-model="newPassword" label="New password" type="password" :error-messages="newPasswordError" 
                            outline single-line @keydown.enter="login" ref="password" prepend-icon="mdi-lock"></v-text-field>
                        <v-text-field v-model="confirmNewPassword" label="Confirm new assword" type="password" :error-messages="confirmNewPasswordError" 
                            outline single-line @keydown.enter="login" ref="password" prepend-icon="mdi-lock"></v-text-field>

                        <v-btn block depressed color="grey darken-4" class="white--text" :loading="loading" @click="changeNLogin">Change password & log in</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
</template>

<script>
import { _st } from '@/softech';
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        username                : '',
        usernameError           : '',
        password                : '',
        passwordError           : '',
        loading                 : false,
        errorMessage            : '',
        
        newPassword             : '',
        confirmNewPassword      : '',
        newPasswordError        : '',
        confirmNewPasswordError : '',
        token                   : '',

        view                    : 'login',
    }),
    computed: {
        ...mapGetters({
            isLoggedIn: 'auth/isLoggedIn'
        }),
        alertVisible() {
            return !_st.isEmpty( this.errorMessage );
        }
    },
    created() {
        if( this.isLoggedIn )
            this.$router.push('/dashboard');
    },
    methods: {
        ...mapActions({
            adminSignIn         : 'auth/login',
            changePwdAndSignIn  : 'auth/changePasswordLogin'
        }),
        async login() {
            if( !this.isValid() ) {
                return;
            }

            try {
                this.loading = true;
                let res = await this.adminSignIn({
                    username: this.username,
                    password: this.password
                });

                this.loading = false;
                if( !res.status && res.error ) {
                    this.mxShowMessage( res.message, 'warning' );
                    return false;
                }

                if( !res.status && !res.error ) {
                    this.errorMessage = res.message;
                    this.password = '';
                    return;
                }

                if( !_st.isEmpty( res.nextStep ) ) {
                    this.view = res.nextStep;
                    this.token = res.token;
                }
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        async changeNLogin() {
            if( !this.isChangeValid() ) {
                return;
            }

            try {
                this.loading = true;
                let res = await this.changePwdAndSignIn({
                    username: this.username,
                    password: this.newPassword,
                    token: this.token
                });

                this.loading = false;
                if( !res.status && res.error ) {
                    this.mxShowMessage( res.message, 'warning' );
                    return false;
                }

                if( !res.status && !res.error ) {
                    this.errorMessage = res.message;
                    this.newPassword = '';
                    this.newPasswordError = '';
                    return;
                }
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        isValid() {
            let errorFound = false;
            this.usernameError = this.passwordError = this.errorMessage = '';

            if( _st.isNUE( this.username ) ) {
                errorFound = true;
                this.usernameError = 'required';
            }
            if( _st.isNUE( this.password ) ) {
                errorFound = true;
                this.passwordError = 'required';
            }

            return !errorFound;
        },
        isChangeValid() {
            let errorFound = false;
            this.usernameError = this.passwordError = this.errorMessage = '';

            if( _st.isNUE( this.username ) ) {
                errorFound = true;
                this.usernameError = 'required';
            }
            if( _st.isNUE( this.newPassword ) ) {
                errorFound = true;
                this.newPasswordError = 'required';
            }
            if( _st.isNUE( this.confirmNewPassword ) ) {
                errorFound = true;
                this.confirmNewPasswordError = 'required';
            }
            if( this.newPassword !== this.confirmNewPassword ) {
                errorFound = true;
                this.confirmNewPasswordError = 'must match the new password';
            }

            return !errorFound;
        }
    },
}
</script>

<style lang="scss" scoped>

</style>